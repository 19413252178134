import React from 'react';
import * as styles from '../assets/styles/about.module.sass';
import * as baseStyles from '../assets/styles/base.module.sass';
import {
	DevTo,
	GitHub,
	Instagram,
	LinkedIn,
	Twitter,
} from '../components/icon';
import { StaticImage as Img } from 'gatsby-plugin-image';

function About() {
	return (
		<>
			<main>
				<div className={baseStyles.container + ' ' + styles.profile}>
					<div className={styles.profileText}>
						<h1 style={{lineHeight: "45px"}}>Shodipo Ayomide</h1>
						<h2>Global Head, Developer Advocacy at Polygon</h2>
						<br />
						<p>
						Hi, i'm Shodipo Ayomide currently doing Developer Advocacy stuff on a global scale at Polygon, building Ethereum-compatible blockchain, and got about 10 years of experience in Technology and a track record in Web & Mobile Engineering, Community Management, and cross-team leadership on a global scale. 
						</p>
						<br />
						<p>
							I've given talks/workshops at developer conferences around various locations and at conferences like, React Atlanta, FutureSync Conference,
							VueJS Amsterdam, VueJS Toronto, APIDAYS Hong Kong, Frontend Love
							Conference Amsterdam, FOSSASIA, Google Developer Groups among
							many, I organizer/co-organize, Developer Circles Lagos from
							Facebook, unStack Africa, Open-Source Community Africa, and
							various other communities.
						</p>
						<br />
						<p>
							Among my latest topics we can find: Talking Decentralized Finance
							and building dApps on Ethereum, Progressive Imaging & Handling:
							React + WebPack, Fast and Furious with VueJS & WebPack, Getting up
							to Speed With Deno, Automating Workflow Processes Using GitHub
							Actions, Design from the Realm of Open-Source, Technical
							Principles to Developer Experience and others.
						</p>
					</div>
					<figure className={styles.profileImageContainer}>
						<div className={styles.profileImage}>
							<Img src='../images/shodipoayomide.jpg' alt='' />
						</div>
						<figcaption>
							<div className={styles.socials}>
								<a
									aria-label='Twitter'
									href='http://twitter.com/developerayo'
									target='_blank'
									rel='noopener noreferrer'>
									<Twitter color='currentColor' />
								</a>
								<a
									aria-label='Github'
									href='https://github.com/developerayo'
									target='_blank'
									rel='noopener noreferrer'>
									<GitHub color='currentColor' />
								</a>
								<a
									aria-label='Instagram'
									href='https://www.instagram.com/shodipoayomide_/'
									target='_blank'
									rel='noopener noreferrer'>
									<Instagram color='currentColor' />
								</a>
								<a
									aria-label='LinkedIn'
									href='https://www.linkedin.com/in/shodipo-ayomide/'
									target='_blank'
									rel='noopener noreferrer'>
									<LinkedIn color='currentColor' />
								</a>
								<a
									aria-label='DevTo'
									href='https://dev.to/developerayo'
									target='_blank'
									rel='noopener noreferrer'>
									<DevTo color='currentColor' />
								</a>
							</div>
						</figcaption>
					</figure>
				</div>
			</main>
		</>
	);
}

export const Head = ({ location: { pathname } }) => {
	const description =
		'Shodipo Ayomide works at Polygon as the Global Head for Developer Advocacy with 10 years of experience in Technology and a track record in Web & Mobile Engineering, Community Management, and cross team leardership on a global scale.';
	const title = 'About | Shodipo Ayomide';

	return (
		<>
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta property='og:locale' content='en_US' />
			<meta property='og:type' content='website' />
			<meta
				property='og:url'
				content={`https://shodipoayomide.com${pathname}`}
			/>
			<meta property='og:logo' content='/favicon.png' size='32x32' />

			{/* Twitter */}
			<meta name='twitter:title' content={title} />
			<meta name='twitter:description' content={description} />

			{/* Open Graph */}
			<meta property='og:title' content={title} key='ogtitle' />
			<meta property='og:description' content={description} key='ogdesc' />
		</>
	);
};

export default About;
